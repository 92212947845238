// From https://react-redux.js.org/using-react-redux/usage-with-typescript
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useRef, useEffect, useState } from "react";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { DataProviderSnapshot } from "./client";
import {
  getConversationOptions,
  getProjectOptions,
  listAliasesOptions,
  listDataproviderSnapshotsOptions,
  listSourcesOptions,
  listTopicsOptions,
  searchProjectsOptions,
} from "./client/@tanstack/react-query.gen";
import {
  fetchDeployments,
  selectDeploymentsData,
  selectDeploymentsStatus,
  selectDeploymentsError,
} from "./slices/deployments";
import {
  FetchTestSuites,
  selectTestSuitesStatus,
  selectTestSuites,
  selectTestSuitesError,
  fetchTestSuites,
  FetchTestSuiteRuns,
  selectTestSuiteRuns,
  fetchTestSuiteRuns,
  FetchTestSuite,
  selectTestCases,
  selectTestCasesStatus,
  selectTestCasesError,
  fetchTestSuite,
  FetchTestSuiteRun,
  selectTestCaseRuns,
  selectTestCaseRunsStatus,
  selectTestCaseRunsError,
  fetchTestSuiteRun,
  selectTestSuiteRunsStatus,
  selectTestSuiteRunsError,
  pollTestSuiteRuns,
  pollTestSuiteRun,
} from "./slices/evaluations";
import {
  fetchProjects,
  selectProjects,
  selectProjectsStatus,
} from "./slices/projects";
import type { RootState, AppDispatch } from "./store";
import { useAuth } from "@clerk/clerk-react";
import { createTopicColorMap, PLACE_HOLDER } from "./colors";

export function useToken() {
  const { getToken } = useAuth();
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const fetchToken = async () => {
      const newToken = await getToken({ template: "api_botbrains_io" });
      setToken(newToken);
    };
    void fetchToken();
  }, [getToken]);

  return token;
}

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// General Helpers
export function useMediaQuery(query: string): boolean {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const mediaQueryList = window.matchMedia(query);
    const listener = (event: MediaQueryListEvent) => {
      setMatches(event.matches);
    };

    setMatches(mediaQueryList.matches);

    mediaQueryList.addEventListener("change", listener);
    return () => {
      mediaQueryList.removeEventListener("change", listener);
    };
  }, [query]);

  return matches;
}

export function useProjects() {
  const dispatch = useAppDispatch();
  const projects = useAppSelector(selectProjects);
  const status = useAppSelector(selectProjectsStatus);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchProjects());
    }
  }, [status, dispatch]);

  return { projects, status };
}

export function useProjectsV2() {
  const query = useQuery({
    ...searchProjectsOptions(),
  });
  return query;
}

export function useProjectV2(projectId: number) {
  const query = useQuery({
    ...getProjectOptions({
      path: {
        project_id: projectId,
      },
    }),
  });
  return query;
}

export function useCurrentProjectV2() {
  const { projectId } = useParams();
  return useProjectV2(Number(projectId));
}

export function useCurrentProjectBreadcrumbs() {
  const { data: currentProject } = useCurrentProjectV2();
  return [
    { href: "/", label: "Projects" },
    { href: `/${currentProject?.id}`, label: currentProject?.name ?? "" },
  ];
}

export function useProject(projectId: string | number | undefined) {
  const { projects, status } = useProjects();
  if (status !== "succeeded") {
    return { project: undefined, status };
  }
  const project = projects.find((project) => project.id === Number(projectId));
  return { project, status };
}

export function useCurrentProject() {
  const { projectId } = useParams();
  return useProject(projectId);
}

export function useTopicColor() {
  const { projectId } = useParams();
  const { data, isPending, error } = useQuery({
    ...listTopicsOptions({
      path: {
        project_id: Number(projectId),
      },
    }),
  });
  if (isPending) {
    return () => PLACE_HOLDER;
  }
  if (error) {
    console.error(error);
    return () => PLACE_HOLDER;
  }
  const getTopicColor = createTopicColorMap(
    data?.data.map((topic) => topic.id)
  );
  return getTopicColor;
}

interface useConversationArgs {
  projectId: number;
  conversationId: string;
}

export function useConversation({
  projectId,
  conversationId,
}: useConversationArgs) {
  const query = useQuery({
    ...getConversationOptions({
      path: {
        project_id: projectId,
        conversation_id: conversationId,
      },
    }),
  });

  return query;
}

interface usePollPendingSnapshots {
  projectId: number;
  dataProviderId: string;
}

export function usePollPendingSnapshots({
  projectId,
  dataProviderId,
}: usePollPendingSnapshots) {
  const queryClient = useQueryClient();
  const prevStatusRef = useRef<string | undefined>();

  const orderSnapshotsDesc = (snapshots: DataProviderSnapshot[]) =>
    snapshots.sort(
      (a, b) =>
        new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
    );

  const query = useQuery({
    ...listDataproviderSnapshotsOptions({
      path: {
        project_id: projectId,
        data_provider_id: dataProviderId,
      },
    }),
    select: (data) => {
      const orderedSnapshots = orderSnapshotsDesc(data.data);
      const latestSnapshot = orderedSnapshots[0];
      const currentStatus = latestSnapshot?.status;

      if (prevStatusRef.current === "PENDING" && currentStatus !== "PENDING") {
        void queryClient.invalidateQueries({
          queryKey: listSourcesOptions({
            path: {
              project_id: projectId,
            },
            query: {
              data_provider_snapshot_id: latestSnapshot.id,
            },
          }).queryKey,
        });
      }

      prevStatusRef.current = currentStatus;
      return { ...data, data: orderedSnapshots };
    },
    refetchInterval: (query) => {
      const latestSnapshot = query.state.data?.data[0];
      return latestSnapshot?.status === "PENDING" ? 10000 : false;
    },
  });

  return {
    snapshots: query.data?.data,
    ...query,
  };
}

export function useTestSuites(args: FetchTestSuites) {
  const dispatch = useAppDispatch();
  const testSuites = useAppSelector(selectTestSuites(args));
  const status = useAppSelector(selectTestSuitesStatus(args));
  const errorMessage = useAppSelector(selectTestSuitesError(args));

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchTestSuites(args));
    }
  }, [dispatch, status, args]);

  return { testSuites, status, errorMessage };
}

export function useTestSuiteRunsByProject(args: FetchTestSuiteRuns) {
  const dispatch = useAppDispatch();
  const testSuiteRuns = useAppSelector(selectTestSuiteRuns(args));
  const status = useAppSelector(selectTestSuiteRunsStatus(args));
  const errorMessage = useAppSelector(selectTestSuiteRunsError(args));

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchTestSuiteRuns(args));
    }
  }, [dispatch, status, args]);

  return { testSuiteRuns, status, errorMessage };
}

export function useTestSuiteRunsByTestSuite({
  project_id,
  testSuiteId,
}: FetchTestSuiteRuns & { testSuiteId: string }) {
  const { testSuiteRuns, status, errorMessage } = useTestSuiteRunsByProject({
    project_id,
  });
  const runs = testSuiteRuns.filter((run) => run.testsuite_id === testSuiteId);
  return { testSuiteRuns: runs, status, errorMessage };
}

export function useTestCasesByTestSuite(args: FetchTestSuite) {
  // Returns all test cases for a test suite. We obtain them by fetching the deep object TestSuite.
  const dispatch = useAppDispatch();
  const testCases = useAppSelector(selectTestCases(args));
  const status = useAppSelector(selectTestCasesStatus(args));
  const errorMessage = useAppSelector(selectTestCasesError(args));

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchTestSuite(args));
    }
  }, [dispatch, status, args]);

  return { testCases, status, errorMessage };
}

export function useTestCase({
  project_id,
  testSuiteId,
  testCaseId,
}: FetchTestSuite & { testCaseId: string }) {
  const { testCases, status, errorMessage } = useTestCasesByTestSuite({
    project_id,
    testSuiteId,
  });
  const testCase = testCases.find((testCase) => testCase.id === testCaseId);
  return { testCase, status, errorMessage };
}

export function useTestCaseRunsByTestSuiteRun({
  project_id,
  testsuite_run_id,
}: FetchTestSuiteRun) {
  const dispatch = useAppDispatch();
  const testCaseRuns = useAppSelector(
    selectTestCaseRuns({ project_id, testsuite_run_id })
  );
  const status = useAppSelector(
    selectTestCaseRunsStatus({ project_id, testsuite_run_id })
  );
  const errorMessage = useAppSelector(
    selectTestCaseRunsError({ project_id, testsuite_run_id })
  );

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchTestSuiteRun({ project_id, testsuite_run_id }));
    }
  }, [dispatch, status, testsuite_run_id]);

  return { testCaseRuns, status, errorMessage };
}

export function usePollPendingTestSuiteRuns({
  project_id,
}: {
  project_id: number;
}) {
  const dispatch = useAppDispatch();
  const testSuiteRuns = useAppSelector(selectTestSuiteRuns({ project_id }));
  const pendingRuns = testSuiteRuns.filter((run) => run.status === "PENDING");

  useEffect(() => {
    if (pendingRuns.length > 0) {
      const id = window.setInterval(() => {
        dispatch(pollTestSuiteRuns({ project_id }));
      }, 5000);
      return () => clearInterval(id);
    }
  }, [pendingRuns.length, dispatch, project_id]);
}

export function usePollPendingTestCaseRuns({
  project_id,
  testsuite_run_id,
}: {
  project_id: number;
  testsuite_run_id: string;
}) {
  const dispatch = useAppDispatch();

  const testCaseRuns = useAppSelector(
    selectTestCaseRuns({ project_id, testsuite_run_id })
  );

  const pendingRuns = testCaseRuns.filter((run) => run.status === "PENDING");

  useEffect(() => {
    if (pendingRuns.length > 0) {
      const id = window.setInterval(() => {
        dispatch(pollTestSuiteRun({ project_id, testsuite_run_id }));
      }, 5000);
      return () => clearInterval(id);
    }
  }, [pendingRuns.length, dispatch, project_id]);
}

export function useDeployments({ projectId }: { projectId: string }) {
  const dispatch = useDispatch<AppDispatch>();
  const deployments = useSelector(selectDeploymentsData({ projectId }));
  const status = useSelector(selectDeploymentsStatus({ projectId }));
  const errorMessage = useSelector(selectDeploymentsError({ projectId }));

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchDeployments({ projectId }));
    }
  }, [dispatch, status, projectId]);

  return { deployments, status, errorMessage };
}

export function useProductionAliases({ project_id }: { project_id: string }) {
  const { deployments, status } = useDeployments({ projectId: project_id });
  const productionAliases = deployments
    .flatMap((d) => d.aliases)
    .filter((a) => a.type == "MUTABLE");
  return { productionAliases, status };
}

export function useProductionAlias({ project_id }: { project_id: number }) {
  const { data, isPending, error } = useQuery({
    ...listAliasesOptions({
      path: { project_id: project_id },
      query: {
        type: "MUTABLE",
      },
    }),
  });

  if (isPending) {
    return null;
  }

  if (error) {
    console.error(error);
    return null;
  }

  if (data?.data.length !== 1) {
    console.error(
      "Expected exactly one production alias, found:",
      data?.data.length
    );
    return null;
  }
  return data?.data[0];
}
