import { AppSidebar } from "@/components/sidebar/AppSidebar";
import { SidebarInset, SidebarProvider } from "@/components/ui/sidebar";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { PageSkeleton } from "../Loading";

export function SidebarLayout() {
  return (
    <SidebarProvider>
      <AppSidebar />
      <SidebarInset className="w-full overflow-x-auto">
        {/* NOTE(memben): div to allow inner sticky positions to work, outer overflow-x-auto to contain SidebarInset, 1rem for Inset Margin*/}
        <div className="h-[calc(100vh-1rem)] px-4">
          <Suspense
            fallback={
              // NOTE(memben): prevent flicker on initial load
              <div className="opacity-0 animate-[fadeIn_200ms_ease-in_100ms_forwards]">
                <PageSkeleton />
              </div>
            }
          >
            <Outlet />
          </Suspense>
          <div className="h-4" />
        </div>
      </SidebarInset>
    </SidebarProvider>
  );
}
