import React, { lazy } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { ClerkProvider } from "@clerk/clerk-react";
import { Provider } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { Toaster } from "@/components/ui/sonner";

import { setupAnalytics } from "./analytics.ts";
import { configureApiClient } from "./api-config.ts";
import ErrorBoundary from "./components/ErrorBoundary";
import EnsureAuthorized from "./EnsureAuthorized.tsx";
import store from "./store";
import { setupMonitoring } from "./sentry.ts";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { SidebarLayout } from "./components/sidebar/SidebarLayout.tsx";

import { isAxiosError } from "axios";
import FrameLocaleGallery from "./misc/FrameLocaleGallery.tsx";

// https://stackoverflow.com/questions/53252861/react-preloading-components-with-lazy-suspense
// Tier 1: Core navigation - preload immediately
const projectDashboardPromise = import("./components/ProjectDashboardPage");
const projectsPagePromise = import("./components/ProjectsPage");

// Tier 2: Main functionality - load after core
const mainFeaturesPromise = projectDashboardPromise.then(() => {
  return Promise.all([
    import("./components/charts/ProjectMetricsPage"),
    import("./components/conversations/ConversationsListPage"),
    import("./components/messages/MessageSearchPage"),
    import("./components/users/UserListPage"),
    import("./components/integration/FrameSelectorPage"),
    import("./components/integration/FrameEditorPage"),
    import("./components/conversations/ConversationPage"),
    import("./components/topics/TopicsPage"),
    import("./components/dataproviders/DataProviderListPage"),
    import("./components/dataproviders/DataProviderPage"),
    import("./components/profiles/ProfilePage"),
    import("./components/evaluation/TestSuitesPage"),
    import("./components/evaluation/TestSuitePage"),
    import("./components/evaluation/TestSuiteCreatePage"),
    import("./components/evaluation/TestSuiteEditPage"),
    import("./components/evaluation/TestSuiteRunsPage"),
    import("./components/evaluation/TestSuiteRunCreatePage"),
    import("./components/evaluation/TestSuiteRunPage"),
    import("./components/evaluation/TestCasePage"),
    import("./components/evaluation/TestCaseEditPage"),
    import("./components/evaluation/TestCaseCreatePage"),
    import("./components/ProjectSettingsPage"),
    import("./components/tables/ListSearchTablesPage"),
    import("./components/ProjectSalesPage"),
    import("./components/SalesPage"),
    import("./components/DashboardToken"),
    import("./components/AdminDashboard"),
  ]);
});

// Tier 3: Heavy components - load last
mainFeaturesPromise.then(() => {
  return Promise.all([
    import("./components/topics/TopicsVisualizationPage"),
    import("./components/tables/SearchTablesPage"),
  ]);
});

// Create lazy components
const ProjectDashboard = lazy(() => projectDashboardPromise);
const ProjectsPage = lazy(() => projectsPagePromise);

// Other components - will use cached promises from above
const ProjectMetrics = lazy(
  () => import("./components/charts/ProjectMetricsPage")
);
const Conversations = lazy(
  () => import("./components/conversations/ConversationsListPage")
);
const MessageSearchPage = lazy(
  () => import("./components/messages/MessageSearchPage")
);
const UserListPage = lazy(() => import("./components/users/UserListPage"));
const FrameEditorSelector = lazy(
  () => import("./components/integration/FrameSelectorPage")
);
const FrameEditorPage = lazy(
  () => import("./components/integration/FrameEditorPage")
);
const ConversationPage = lazy(
  () => import("./components/conversations/ConversationPage")
);
const TopicsPage = lazy(() => import("./components/topics/TopicsPage"));
const TopicsVisualizationPage = lazy(
  () => import("./components/topics/TopicsVisualizationPage")
);
const DataProvidersPage = lazy(
  () => import("./components/dataproviders/DataProviderListPage")
);
const DataProviderPage = lazy(
  () => import("./components/dataproviders/DataProviderPage")
);
const ProfilePage = lazy(() => import("./components/profiles/ProfilePage"));
const TestSuitesPage = lazy(
  () => import("./components/evaluation/TestSuitesPage")
);
const TestSuitePage = lazy(
  () => import("./components/evaluation/TestSuitePage")
);
const TestSuiteCreatePage = lazy(
  () => import("./components/evaluation/TestSuiteCreatePage")
);
const TestSuiteEditorPage = lazy(
  () => import("./components/evaluation/TestSuiteEditPage")
);
const TestSuiteRunsPage = lazy(
  () => import("./components/evaluation/TestSuiteRunsPage")
);
const TestSuiteRunCreatePage = lazy(
  () => import("./components/evaluation/TestSuiteRunCreatePage")
);
const TestSuiteRunPage = lazy(
  () => import("./components/evaluation/TestSuiteRunPage")
);
const TestCasePage = lazy(() => import("./components/evaluation/TestCasePage"));
const TestCaseEditPage = lazy(
  () => import("./components/evaluation/TestCaseEditPage")
);
const TestCaseCreatePage = lazy(
  () => import("./components/evaluation/TestCaseCreatePage")
);
const ProjectSettingsPage = lazy(
  () => import("./components/ProjectSettingsPage")
);
const ListSearchTablesPage = lazy(
  () => import("./components/tables/ListSearchTablesPage")
);
const SearchTablesPage = lazy(
  () => import("./components/tables/SearchTablesPage")
);
const ProjectSalesPage = lazy(() => import("./components/ProjectSalesPage"));
const SalesPage = lazy(() => import("./components/SalesPage"));
const DashboardToken = lazy(() => import("./components/DashboardToken"));
const AdminDashboard = lazy(() => import("./components/AdminDashboard"));

// Import your publishable key
const PUBLISHABLE_KEY = import.meta.env.VITE_CLERK_PUBLISHABLE_KEY;
if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

setupMonitoring();
setupAnalytics();
configureApiClient();

const router = createBrowserRouter([
  {
    path: "/frame-locale-gallery",
    element: <FrameLocaleGallery />,
  },
  {
    path: "/",
    element: <EnsureAuthorized />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "/",
        element: <SidebarLayout />,
        children: [
          { path: "/", element: <ProjectsPage /> },
          {
            path: "/:projectId",
            element: <ProjectDashboard />,
          },
          {
            // NOTE(memben): doesn't need to be nested under a project
            path: "/:projectId/userpool/:userPoolId",
            element: <UserListPage />,
          },
          {
            path: "/:projectId/metrics",
            element: <ProjectMetrics />,
          },
          {
            path: "/:projectId/conversations",
            element: <Conversations />,
          },
          {
            path: "/:projectId/messages/search",
            element: <MessageSearchPage />,
          },
          {
            path: "/:projectId/frames",
            element: <FrameEditorSelector />,
          },
          {
            path: "/:projectId/frames/:frameId",
            element: <FrameEditorPage />,
          },
          {
            path: "/:projectId/conversations/:conversationId",
            element: <ConversationPage />,
          },
          {
            path: "/:projectId/topics",
            element: <TopicsPage />,
          },
          {
            path: "/:projectId/topics-visualization",
            element: <TopicsVisualizationPage />,
          },
          {
            path: "/:projectId/dataproviders",
            element: <DataProvidersPage />,
          },
          {
            path: "/:projectId/dataproviders/:providerId",
            element: <DataProviderPage />,
          },
          {
            path: "/:projectId/profiles",
            element: <ProfilePage />,
          },
          {
            path: "/:projectId/testsuites",
            element: <TestSuitesPage />,
          },
          {
            path: "/:projectId/testsuites/:testSuiteId",
            element: <TestSuitePage />,
          },
          {
            path: "/:projectId/testsuites/create",
            element: <TestSuiteCreatePage />,
          },
          {
            path: "/:projectId/testsuites/:testSuiteId/edit",
            element: <TestSuiteEditorPage />,
          },
          {
            path: "/:projectId/testsuites/:testSuiteId/runs",
            element: <TestSuiteRunsPage />,
          },
          {
            path: "/:projectId/testsuites/:testSuiteId/runs/create",
            element: <TestSuiteRunCreatePage />,
          },
          {
            path: "/:projectId/testsuites/:testSuiteId/runs/:testSuiteRunId",
            element: <TestSuiteRunPage />,
          },
          {
            path: "/:projectId/testsuites/:testSuiteId/testcases/:testCaseId",
            element: <TestCasePage />,
          },
          {
            path: "/:projectId/testsuites/:testSuiteId/testcases/:testCaseId/edit",
            element: <TestCaseEditPage />,
          },
          {
            path: "/:projectId/testsuites/:testSuiteId/testcases/create",
            element: <TestCaseCreatePage />,
          },
          {
            path: "/:projectId/settings",
            element: <ProjectSettingsPage />,
          },
          {
            path: "/:projectId/tables",
            element: <ListSearchTablesPage />,
          },
          {
            path: "/:projectId/tables/:tableId",
            element: <SearchTablesPage />,
          },
          {
            path: "/:projectId/sales",
            element: <ProjectSalesPage />,
          },
          {
            path: "/sales",
            element: <SalesPage />,
          },
          { path: "/token", element: <DashboardToken /> },
          {
            path: "/admin",
            element: <AdminDashboard />,
          },
          {
            path: "/break",
            element: (
              <button
                onClick={() => {
                  throw new Error("Error Monitoring Test");
                }}
              >
                Error Monitoring: Throw Error
              </button>
            ),
          },
        ],
      },
    ],
  },
]);

const MAX_RETRIES = 3;
// NOTE(memben): 404 debatable - for now we don't retry
const HTTP_STATUS_TO_NOT_RETRY = [400, 401, 403, 404];

// https://github.com/TanStack/query/discussions/372#discussioncomment-6023276
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (failureCount > MAX_RETRIES) {
          return false;
        }
        if (
          isAxiosError(error) &&
          HTTP_STATUS_TO_NOT_RETRY.includes(error.response?.status ?? 0)
        ) {
          console.log(`Aborting retry due to ${error.response?.status} status`);
          return false;
        }

        return true;
      },
    },
  },
});

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          {/* NOTE(memben): Suspense is in the SidebarLayout component */}
          <RouterProvider router={router} />
        </Provider>
        <ReactQueryDevtools
          initialIsOpen={false}
          buttonPosition="bottom-right"
        />
      </QueryClientProvider>
    </ClerkProvider>
    <Toaster />
  </React.StrictMode>
);
